import { SVGProps, useId } from "react";

const Flame16Icon = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11C13.2091 15 15 13.2091 15 11V5C15 2.79086 13.2091 1 11 1H5ZM12 8.38612C12 11.0773 10.098 12.118 8.62701 12.408C9.11576 12.2381 9.64706 11.8021 9.64706 10.8543C9.64706 10.2537 9.26184 9.88317 8.96005 9.70971C8.86772 9.65663 8.76052 9.72346 8.75224 9.82829C8.72607 10.1606 8.4016 10.4254 8.18052 10.1727C7.98518 9.94936 7.90311 9.62312 7.90311 9.41444V9.14175C7.90311 8.97736 7.73482 8.86842 7.59106 8.95232C7.0565 9.26421 6.35294 9.90585 6.35294 10.8543C6.35294 12.0668 7.34649 12.4417 7.96099 12.4934C7.88179 12.4979 7.80569 12.5 7.73332 12.5C6.48889 12.5 4 11.6772 4 8.38612C4 7.09877 4.68775 6.23416 5.34586 5.74042C5.71219 5.46558 6.17901 5.73923 6.22596 6.19001L6.26635 6.57777C6.31554 7.04999 6.75262 7.43713 7.15733 7.17846C7.95002 6.67183 8.23529 5.45378 8.23529 4.78647V3.94023C8.23529 3.60938 8.57576 3.39071 8.86659 3.55699C10.1773 4.30632 12 5.93711 12 8.38612Z"
        fill={`url(#paint0_linear_${id})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="15"
          y1="15"
          x2="3.54618"
          y2="-0.841013"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffb800" />
          <stop offset="1" stopColor="#ff007a" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Flame16Icon;
